
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Subscriptions</h5>
      <div class="row mb-2 gy-2">
        <div class="col-sm-12 col-md-6 d-flex">
          <div id="tickets-table_length" class="me-2 dataTables_length">
            <label class="d-inline-flex mb-0 align-items-center">
              <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
            </label>
          </div>
          <a class="btn btn-primary" :href="absoluteUrl('/checkout/multiple-device-plan')">
              <i class="ri align-bottom ri-add-fill"></i>  Create plan </a>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-inline text-sm-end">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
              <label class="d-inline-flex mb-0 align-items-center">
                <b-form-input v-model="searchQuery" type="search" placeholder="Search id..." class="form-control"></b-form-input>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-5">
        <div class="card-body">
          <div class="table-responsive mb-0">
            <b-table striped hover table-class="table table-centered w-100" thead-tr-class=""
               ref="selectableTable" :items="paginatedItems"
               :fields="columns" responsive="sm">
                <template v-slot:cell(id)="data">
                  <div style="min-width:6rem;">
                    {{data.item.friendly_id}}</div>
                </template>
                <template v-slot:cell(amount)="data">
                  <span> ${{$filters.money_format(data.item.amount)}} </span>
                </template>
                <template v-slot:cell(current_period_start)="data">
                  <div style="min-width:7rem;"> {{$filters.date_time(data.item.current_period_start,1)}} </div>
                </template>
                <template v-slot:cell(current_period_end)="data">
                  <div style="min-width:7rem;"> {{$filters.date_time(data.item.current_period_end,1)}} </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div><span v-html="statusBadge(data.item.status)"> </span></div>
                </template>
                <template v-slot:cell(action)="data">
                  <router-link :to="`/subscriptions/${data.item.id}`" class="">
                    view</router-link>
                </template>
            </b-table>
          </div>
          <div v-if="!subscriptions.length" class="fs-5 py-3 text-muted text-center">
            <p class="mb-0">There is no subscription to show.</p>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded">
                  <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" 
                  :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "subscription-list",
  data() {
    return {
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      selected: [],
      isSelectAll: false,
      columns: [
          {
            key: "id",
            label: "ID",
          },
          {
            key: "amount",
            label: "Amount",
          },
          {
            key: "interval",
            label: "Interval",
          },
          {
            key: "current_period_start",
            label: "Period start",
          },
          {
            key: "current_period_end",
            label: "Period end",
          },
          {
            key: "status",
            label: "Status",
          },
          "action"
      ],
    }
  },
  computed: {
    subscriptions(){
      return this.$store.state.subscriptions
    },
    searchedItems(){
      return this.subscriptions.filter(item => this.searchQuery ? item.id == this.searchQuery : true )
    },
    paginatedItems(){
        return this.searchedItems.slice(
          this.paginateStart,
          this.paginateEnd
        );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.subscriptions.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    fetchSubscriptions() {
      this.$store.dispatch("fetchSubscriptions")
    },
  },
  created(){
    this.$store.dispatch("fetchSubscriptions")
  },
}

</script>

